import { makeStyles } from '@material-ui/styles'
import { Icon, Link } from '@system'
import React from 'react'
import SbEditable from 'storyblok-react'

const useStyles = makeStyles(() => ({
  socialLinkRoot: {
    display: 'block',
    '& > svg': {
      display: 'block',
    },
  },
}))

const SocialLink = ({ blok }) => {
  const { socialNetwork, url } = blok
  const classes = useStyles()

  return (
    <SbEditable content={blok}>
      {!!url && (
        <Link link={url} linkStyle={classes.socialLinkRoot}>
          <Icon>{socialNetwork}</Icon>
        </Link>
      )}
    </SbEditable>
  )
}

export default SocialLink
